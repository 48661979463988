import { Upload } from 'tus-js-client'

import supabase, { supabaseUrl } from '@/supabase'
import { base64ToArrayBuffer } from '@/utils'
import Semaphore from '@/utils/semaphore'

class ImagesApi {

  private semaphore = new Semaphore(10)

  public async fetchImageUrl(bucketName: ImageBucketName, imageId: string, transform: TransformOptions): Promise<{ url: string, expiresAt: Date }> {
    return this.semaphore.use(async () => {
      const expiresIn = 60 * 60 * 24 // 24 hours
      const expiresAt = new Date(Date.now() + expiresIn * 1000)
      const { data, error } = await supabase
        .storage
        .from(bucketName)
        .createSignedUrl(imageId, expiresIn, { transform: { width: transform?.size, height: transform?.size, ...transform } })
      if (error) throw error
      return {
        url: data.signedUrl,
        expiresAt
      }
    }, transform.size)
  }

  public async downloadImage(bucketName: ImageBucketName, imageId: string): Promise<Blob> {
    const path = `${imageId}?cacheBreaker=${Date.now()}`
    const { data, error } = await supabase
      .storage
      .from(bucketName)
      .download(path, { transform: { format: 'origin', quality: 100 } })
    if (error) throw error
    return data
  }

  public async uploadImage(bucketName: ImageBucketName, file: File | Blob, imageId = crypto.randomUUID().toString(), onProgress?: (progress: number) => void): Promise<string> {
    const { data, error } = await supabase.auth.getSession()
    if (error) throw error
    if (!data.session) throw new Error('No active session')

    return new Promise((resolve, reject) => {
      const upload = new Upload(file, {
        endpoint: `${supabaseUrl}/storage/v1/upload/resumable`,
        retryDelays: [0, 1000, 2000, 4000, 8000],
        headers: {
          authorization: `Bearer ${data.session.access_token}`,
          'x-upsert': 'true'
        },
        uploadDataDuringCreation: true,
        removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
        metadata: {
          bucketName,
          objectName: imageId,
          contentType: file.type,
          cacheControl: '3600',
        },
        chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
        onError: reject,
        onProgress: (bytesUploaded, bytesTotal) => {
          if (!onProgress) return
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          onProgress(Number(percentage))
        },
        onSuccess: () => resolve(imageId),
      })

      // Check if there are any previous uploads to continue.
      return upload.findPreviousUploads().then((previousUploads) => {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0])
        }

        // Start the upload
        upload.start()
      })
    })
  }

  public async deleteImage(bucketName: ImageBucketName, imageId: string): Promise<void> {
    const { error } = await supabase
      .storage
      .from(bucketName)
      .remove([imageId])
    if (error) throw error
  }

  public async rotateImage(bucketName: ImageBucketName, imageId: string, rotation: -90 | 90): Promise<File> {
    const { data, error } = await supabase.functions.invoke<{ image: string, contentType: string }>('rotate-image', { body: { imageId, rotation, bucketName } })
    if (error) {
      throw error
    }
    if (!data) {
      throw new Error('No data returned from rotate-image function')
    }

    const arrayBuffer = base64ToArrayBuffer(data.image)
    const blob = new Blob([arrayBuffer], { type: data.contentType })
    return new File([blob], imageId, {
      type: data.contentType,
      lastModified: new Date().getTime()
    })
  }
}

export default new ImagesApi()

import { RealtimeChannel } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { reactive } from 'vue'

import { captureException } from '@/sentry'
import supabase from '@/supabase'
import type { Callback } from '@/types/supabase'

export type Table = 'profiles' | 'feedback_messages' | 'feedback_messages_read' | 'cars' | 'connection_requests' | 'connections' | 'profiles_read' | 'connections_read' | 'cars_read'
export type Component = 'ProfilesManagementPage' | 'UserFeedbackPage' | 'CarsPage' | 'DashboardPage' | 'MyNetworkPage' | 'DealersView' | 'RequestsView' | 'ConnectionsView' | 'TheSidebar'

export const useRealtimeStore = defineStore('realtime', () => {
  const channels = reactive<Map<Table, { callbacks: { callback: Callback, component: Component }[], channel: RealtimeChannel }>>(new Map())

  const subscribe = (component: Component, tables: Table[], callback: Callback): void => {
    tables.forEach(table => {
      const channel = channels.get(table)
      if (channel) {
        channel.callbacks.push({ callback, component })
        return
      }

      const c = supabase
        .channel(`realtime-${table}`)
        .on('postgres_changes', { event: '*', schema: '*', table }, payload => {
          channels.get(table)?.callbacks.forEach(({ callback }) => callback(payload))
        })
        .subscribe((status, error) => {
          if (error) captureException(error)
        })
      channels.set(table, { callbacks: [{ callback, component }], channel: c })
    })
  }

  const unsubscribe = (component: Component): void => {
    const tables = Array.from(channels.keys())
      .filter(table => channels.get(table)?.callbacks.some(({ component: c }) => c === component))
    tables.forEach(table => {
      const channel = channels.get(table)!
      channel.callbacks = channel.callbacks.filter(({ component: c }) => c !== component)
      if (channel.callbacks.length === 0) {
        channel.channel.unsubscribe()
          .catch(error => captureException(error))
          .finally(() => channels.delete(table))
      }
    })
  }

  const onUnmounted = async (): Promise<void> => {
    const unsubscribePromises = Array.from(channels.values())
      .map(({ channel }) => channel.unsubscribe().catch(error => captureException(error)))
    await Promise.all(unsubscribePromises)
    channels.clear()
  }

  return {
    subscribe,
    unsubscribe,
    onUnmounted
  }
})

import type { SeverityLevel } from '@sentry/vue'
import * as Sentry from '@sentry/vue'

export function captureInfo (message: string, extras?: Record<string, unknown>): string {
  return send(() => Sentry.captureMessage(message), 'info', message, extras)
}

export function captureException (error: unknown, extras?: Record<string, unknown>): string {
  const captureFunction = error instanceof Error
    ? () => Sentry.captureException(error)
    : () => Sentry.captureMessage(JSON.stringify(error))
  return send(captureFunction, 'error', error, extras)
}

const send = <T>(
  sendFn: () => string,
  severity: SeverityLevel,
  error: T,
  extras?: Record<string, unknown>
): string => {
  if (!import.meta.env.VITE_PUBLIC_VERCEL_ENV) {
    const message = (error as Error).message ?? 'n/a'
    // eslint-disable-next-line no-console
    console.error('SENTRY: ', message, error ?? '', extras ?? '', new Error().stack)
  }

  return Sentry.withScope((scope) => {
    if (extras) {
      Object.keys(extras).forEach(key => {
        const value = extras[key]
        scope.setExtra(key, value ? JSON.stringify(value) : undefined)
      })
    }
    scope.setLevel(severity)
    return sendFn()
  })
}

<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    aria-label="profile-avatar"
    class="relative flex aspect-square flex-grow"
  >
    <CarvisSpinner
      v-show="loading"
      class="absolute z-10"
    />
    <img
      v-if="avatarImgUrl"
      class="avatar"
      :src="avatarImgUrl"
      @load="loading = false"
    >
    <div
      v-else-if="avatarSvg"
      class="avatar"
      v-html="avatarSvg"
    />
  </div>
</template>

<script setup lang="ts">
import multiavatar from '@multiavatar/multiavatar/esm'
import { onMounted, ref, watch } from 'vue'

import { useImagesStore } from '@/stores/images-store'
import { useToastsStore } from '@/stores/toasts-store'

const props = defineProps<{
  profileId: string | null
  avatarImageId: string | null
  imageIdOverride?: string
}>()

const loading = ref(true)
const avatarImgUrl = ref<string | null>(null)
const avatarSvg = ref<string | null>(null)
const toastsStore = useToastsStore()
const imagesStore = useImagesStore()

const loadAvatarFromImageId = async (imageId: string): Promise<void> => {
  try {
    const imageUrl = await imagesStore.fetchAvatarImageUrl(imageId, { size: 200, resize: 'contain' })
    avatarImgUrl.value = imageUrl
  } catch (error) {
    toastsStore.error('Fehler beim Laden eines Profilfotos', 'Beim Laden eines Profilfotos ist ein Fehler aufgetreten. Bitte versuche es erneut.', error as Error)
    loading.value = false
  }
}

const loadAvatar = (): void => {
  loading.value = true
  if (props.imageIdOverride) {
    loadAvatarFromImageId(props.imageIdOverride)
  } else if (props.avatarImageId) {
    loadAvatarFromImageId(props.avatarImageId)
  } else if (props.profileId) {
    avatarSvg.value = multiavatar(props.profileId)
    loading.value = false
  }
}

watch(() => props.avatarImageId, loadAvatar)
watch(() => props.imageIdOverride, loadAvatar)
watch(() => props.profileId, loadAvatar)
onMounted(() => loadAvatar())
</script>

<style scoped>
.avatar {
  @apply rounded-full w-full h-full object-cover
}
</style>

import { defineStore, type StateTree } from 'pinia'
import { ref } from 'vue'

import imagesApi from '@/api/images/images-api'

const toImageCacheKey = (key: ImageCacheKey): string => `${key.imageId}-${key.size}-${key.quality ?? 'default'}-${key.resize ?? 'none'}`

const serialize = (data: StateTree): string => {
  const v = data.imagesCache as Map<ImageCacheKey, ImageCacheValue>
  const arr = Array.from(v)
    .filter(([, val]) => val.expiresAt > new Date())
    .map(([key, val]) => ([key, { ...val, expiresAt: val.expiresAt.toISOString() }]))
  return JSON.stringify(arr)
}

const deserialize = (raw: string): StateTree => {
  const parsed = JSON.parse(raw)
  const entries = parsed
    .map(([key, val]: [ImageCacheKey, ImageCacheValue]) => [key, { ...val, expiresAt: new Date(val.expiresAt) }])
    .filter(([, val]: [ImageCacheKey, ImageCacheValue]) => val.expiresAt > new Date()) as [ImageCacheKey, ImageCacheValue][]
  const imagesCache = new Map(entries)
  return { imagesCache }
}

type ImageCacheKey = { imageId: string, size: 32 | 200 | 400 | 1080, quality?: number, resize?: 'cover' | 'contain' | 'fill'}
type ImageCacheValue = { url: string, expiresAt: Date }


export const useImagesStore = defineStore('images', () => {
  const imagesCache = ref(new Map<string, ImageCacheValue>())

  const fetchCarImageUrl = async (imageId: string, transform: TransformOptions): Promise<string> => {
    return await fetchImageUrl('car_images', imageId, transform)
  }

  const fetchAvatarImageUrl = async (imageId: string, transform: TransformOptions): Promise<string> => {
    return await fetchImageUrl('avatars', imageId, transform)
  }

  const fetchImageUrl = async (bucketName: ImageBucketName, imageId: string, transform: TransformOptions): Promise<string> => {
    const key = toImageCacheKey({ imageId, ...transform })
    const cachedImage = imagesCache.value.get(key)
    if (cachedImage) {
      if (cachedImage.expiresAt > new Date()) {
        return cachedImage.url
      }
    }

    const { url, expiresAt } = await imagesApi.fetchImageUrl(bucketName, imageId, transform)
    imagesCache.value.set(key, { url, expiresAt })
    return url
  }

  const deleteCarImage = async (imageId: string): Promise<void> => {
    return await deleteImage('car_images', imageId)
  }

  const deleteAvatarImage = async (imageId: string): Promise<void> => {
    return await deleteImage('avatars', imageId)
  }

  const deleteImage = async (bucketName: ImageBucketName, imageId: string): Promise<void> => {
    await imagesApi.deleteImage(bucketName, imageId)
    imagesCache.value.forEach((_, key) => {
      if (key.startsWith(imageId)) imagesCache.value.delete(key)
    })
  }

  const uploadCarImage = async (file: File | Blob, imageId?: string, onProgress?: (progress: number) => void): Promise<string> => {
    return await imagesApi.uploadImage('car_images', file, imageId, onProgress)
  }

  const uploadAvatarImage = async (file: File | Blob, imageId?: string, onProgress?: (progress: number) => void): Promise<string> => {
    return imagesApi.uploadImage('avatars', file, imageId, onProgress)
  }

  const resizeCarImage = async (file: File, maxHeight: number): Promise<Blob> => {
    return await resizeImage(file, maxHeight)
  }

  const rotateCarImage = async (imageId: string, degree: -90 | 90): Promise<Blob> => {
    const rotatedImage = await imagesApi.rotateImage('car_images', imageId, degree)
    imagesCache.value.forEach((_, key) => {
      if (key.startsWith(imageId)) imagesCache.value.delete(key)
    })
    return rotatedImage
  }

  return {
    imagesCache, // Expose cache for persist plugin
    fetchCarImageUrl,
    fetchAvatarImageUrl,
    deleteCarImage,
    deleteAvatarImage,
    rotateCarImage,
    uploadAvatarImage,
    uploadCarImage,
    resizeCarImage
  }
}, {
  persist: {
    debug: true,
    serializer: {
      serialize,
      deserialize
    }
  }
})

export const resizeImage = (file: File, maxHeight: number): Promise<Blob> => {
  const reader = new FileReader()
  const contentType = file.type
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const img = new Image()
      if (e.target === null) return reject(new Error('Failed to resize image'))
      if (typeof e.target.result !== 'string') return reject(new Error('Failed to resize image'))
      img.src = e.target.result

      img.onload = async () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        // Calculate the new dimensions while maintaining the aspect ratio
        let newWidth = img.width
        let newHeight = img.height
        if (img.height > maxHeight) {
          const ratio = maxHeight / img.height
          newHeight = maxHeight
          newWidth = img.width * ratio
        }

        // Set canvas dimensions
        canvas.width = newWidth
        canvas.height = newHeight

        // Draw the image on the canvas with the new dimensions
        if (!ctx) return reject(new Error('Failed to resize image'))
        ctx.drawImage(img, 0, 0, newWidth, newHeight)

        // Convert the canvas content to a a blob
        canvas.toBlob(blob => {
          if (!blob) return reject(new Error('Failed to resize image'))
          resolve(blob)
        }, contentType, 0.8)
      }
    }
  })
}

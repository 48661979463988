import '@/assets/main.css'

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import PrimeVue from 'primevue/config'
import { register as registerSwiper } from 'swiper/element/bundle'
import { createApp } from 'vue'

import App from '@/App.vue'
import CarvisBadge from '@/components/generic/CarvisBadge.vue'
import CarvisButton from '@/components/generic/CarvisButton.vue'
import CarvisCheckbox from '@/components/generic/CarvisCheckbox.vue'
import CarvisChip from '@/components/generic/CarvisChip.vue'
import CarvisCombobox from '@/components/generic/CarvisCombobox.vue'
import CarvisDialog from '@/components/generic/CarvisDialog.vue'
import CarvisDropdown from '@/components/generic/CarvisDropdown.vue'
import CarvisFlash from '@/components/generic/CarvisFlash.vue'
import CarvisForm from '@/components/generic/CarvisForm.vue'
import CarvisH1 from '@/components/generic/CarvisH1.vue'
import CarvisH2 from '@/components/generic/CarvisH2.vue'
import CarvisIcon from '@/components/generic/CarvisIcon.vue'
import CarvisImage from '@/components/generic/CarvisImage.vue'
import CarvisInput from '@/components/generic/CarvisInput.vue'
import CarvisLabel from '@/components/generic/CarvisLabel.vue'
import CarvisLink from '@/components/generic/CarvisLink.vue'
import CarvisPlaceholder from '@/components/generic/CarvisPlaceholder.vue'
import CarvisProgressBar from '@/components/generic/CarvisProgressBar.vue'
import CarvisRangeSlider from '@/components/generic/CarvisRangeSlider.vue'
import CarvisSpinner from '@/components/generic/CarvisSpinner.vue'
import CarvisTable from '@/components/generic/CarvisTable.vue'
import CarvisTableSort from '@/components/generic/CarvisTableSort.vue'
import CarvisTabs from '@/components/generic/CarvisTabs.vue'
import CarvisText from '@/components/generic/CarvisText.vue'
import CarvisTextArea from '@/components/generic/CarvisTextArea.vue'
import CarvisTextField from '@/components/generic/CarvisTextField.vue'
import CarvisTile from '@/components/generic/CarvisTile.vue'
import CarvisToggle from '@/components/generic/CarvisToggle.vue'
import CarvisToolTip from '@/components/generic/CarvisToolTip.vue'
import CarvisUnitTextField from '@/components/generic/CarvisUnitTextField.vue'
import router from '@/router'
import sentryPlugin from '@/sentry/plugin'

registerSwiper()

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createApp(App)
  .component('CarvisFlash', CarvisFlash)
  .component('CarvisButton', CarvisButton)
  .component('CarvisCheckbox', CarvisCheckbox)
  .component('CarvisChip', CarvisChip)
  .component('CarvisCombobox', CarvisCombobox)
  .component('CarvisBadge', CarvisBadge)
  .component('CarvisDialog', CarvisDialog)
  .component('CarvisDropdown', CarvisDropdown)
  .component('CarvisForm', CarvisForm)
  .component('CarvisH1', CarvisH1)
  .component('CarvisH2', CarvisH2)
  .component('CarvisIcon', CarvisIcon)
  .component('CarvisImage', CarvisImage)
  .component('CarvisInput', CarvisInput)
  .component('CarvisLabel', CarvisLabel)
  .component('CarvisLink', CarvisLink)
  .component('CarvisPlaceholder', CarvisPlaceholder)
  .component('CarvisProgressBar', CarvisProgressBar)
  .component('CarvisRangeSlider', CarvisRangeSlider)
  .component('CarvisSpinner', CarvisSpinner)
  .component('CarvisTable', CarvisTable)
  .component('CarvisTableSort', CarvisTableSort)
  .component('CarvisTabs', CarvisTabs)
  .component('CarvisText', CarvisText)
  .component('CarvisTextArea', CarvisTextArea)
  .component('CarvisTextField', CarvisTextField)
  .component('CarvisTile', CarvisTile)
  .component('CarvisToggle', CarvisToggle)
  .component('CarvisToolTip', CarvisToolTip)
  .component('CarvisUnitTextField', CarvisUnitTextField)
  .use(pinia)
  .use(router)
  .use(sentryPlugin)
  .use(autoAnimatePlugin)
  .use(PrimeVue, { unstyled: true })
  .mount('#app')
